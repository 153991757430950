import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PricesService {

  constructor() {

  }

  init(itinerary) {
    if(!itinerary || !itinerary.segments) {
      return itinerary;
    }

    itinerary = this.addDefaultProperty(itinerary);
    itinerary = this.addPriceDifference(itinerary);

 

    return itinerary;
  }

  addDefaultProperty(itinerary)
  {
    itinerary.segments.forEach(segment => {
      segment.elements.forEach(element => {
        if(!element.optional) {
          element.isDefault = true;
        }
      })
    });

    return itinerary;
  }

  addPriceDifference(itinerary)
  {
    let currentPrice = 0;
    let extraUnitIds = itinerary.extraUnitIds ? itinerary.extraUnitIds : [];

    itinerary.segments.forEach(segment => {
      let groups = [];
      let groupCount = 0;

      segment.elements.forEach((element, i) => {
        if(element.isDefault && i > 0) groupCount = groupCount + 1;
        if(typeof groups[groupCount] == 'undefined') groups[groupCount] = [];

        groups[groupCount].push(element);

      });

      groups.forEach(group => {
        let basePrice = 0;

        group.forEach(element => {
          if(extraUnitIds.indexOf(element.unit) > -1) {
            element.addedValue = element.olPrices.salesTotal;
            element.addedValuePerPerson = (element.addedValue / Object.keys(element.olPrices.participants).length).toFixed(2);
            element.addedValuePerPersonInt = (element.addedValue / Object.keys(element.olPrices.participants).length).toFixed(0);
            element.addedValuePerNight = (element.addedValue / element.nights).toFixed(2);
            element.addedValuePerPersonPerNightInt = (element.addedValuePerPerson / element.nights).toFixed(0);
            element.addedValuePerDay = (element.addedValue / (element.nights + 1)).toFixed(2);
            if(!element.addedValueDisplay) {
              element.addedValueDisplay = element.addedValue;
              element.addedValueDisplayPerNight = (element.addedValue / element.nights).toFixed(2);
              element.addedValueDisplayPerPersonPerNightInt = (element.addedValuePerPerson / element.nights).toFixed(0);
              element.addedValueDisplayPerPerson = element.addedValuePerPerson;
            }
          } else if(element.prices && element.optional == false) {
            basePrice = element.prices.price;
          }
        });

        group.forEach(element => {
          if(element.prices) {
            element.addedValue = Math.round((element.prices.price - basePrice) * 100) / 100;
            element.addedValuePerPerson = (element.addedValue / Object.keys(element.prices.participantPrices).length).toFixed(2);
            element.addedValuePerPersonInt = (element.addedValue / Object.keys(element.prices.participantPrices).length).toFixed(0);
            element.addedValuePerNight = (element.addedValue / element.nights).toFixed(2);
            element.addedValuePerPersonPerNightInt = (element.addedValuePerPerson / element.nights).toFixed(0);
            element.addedValuePerDay = (element.addedValue / (element.nights + 1)).toFixed(2);
            // element.addedValuePerDay = (element.addedValue / (element.nights + 1)).toFixed(2);
            if(!element.addedValueDisplay) {
              element.addedValueDisplay = element.addedValue;
              element.addedValueDisplayPerNight = (element.addedValue / element.nights).toFixed(2);
              element.addedValueDisplayPerPersonPerNightInt = (element.addedValuePerPerson / element.nights).toFixed(0);
              element.addedValueDisplayPerPerson = element.addedValuePerPerson;
            }
          }
        })
      });
    });  

    return itinerary;
  }

  doToggle(itinerary, data)
  {
    data.forEach(obj => {
      itinerary.segments.forEach(segment => {
        if(segment.TSBlock.id !== obj.blockId) return;
        segment.elements.forEach((element, index) => {
          if(element.TSOrderline.id == obj.lineId) {
            if(obj.participants)
              element.prices.price = this.calculateSalesTotal(obj.participants, element);

            if(element.optional) {
              element.optional = false;
            } else {
              element.optional = true;
            }
          }
        });
      });
    });

    return itinerary;
  }

  doObjectToggle(itinerary, data, optional)
  {
    data.forEach(obj => {
      itinerary.segments.forEach(segment => {
        if(segment.vtbObjectId !== obj.blockId) return;
        segment.elements.forEach((element, index) => {
          if(element.vtbObjectId == obj.lineId) {
            if(obj.participants)
              element.prices.price = this.calculateSalesTotal(obj.participants, element);
            
            if(optional === true  || optional === false) {
              element.optional = optional;
            } else {
              if(element.optional) {
                element.optional = false;
              } else {
                element.optional = true;
              }
            }            
          }
        });
      });
    });

    return itinerary;
  }


  
  changeParticipants(itinerary, data)
  {
    data.forEach(obj => {
      itinerary.segments.forEach(segment => {
        
        if(segment.vtbObjectId !== obj.blockId) return;
        segment.elements.forEach((element, index) => {
          if(element.vtbObjectId == obj.lineId) {
            if(obj.participants.length == 0) {
              element.optional = true;
            } else if(obj.participants) {
              // element.optional = false;
              element.prices.price = this.calculateSalesTotal(obj.participants, element);
            }
          }
          
        });
      });
    });

    return itinerary;
  }

  calculateSalesTotal(participants, element)
  {
    let value = 0;
    Object.keys(element.prices.participantPrices).forEach(key => {
      if(participants.indexOf(key) > -1)
        value += element.prices.participantPrices[key].salesPrice;
    });

    return value;
  }

  includeElement(itinerary, data)
  {
    data.forEach(obj => {
      itinerary.segments.forEach(segment => {
        if(segment.TSBlock.id !== obj.blockId) return;
        segment.elements.forEach((element, index) => {
          if(element.TSOrderline.id == obj.lineId) {
            element.optional = false;
          }
        });
      });
    });

    return itinerary;
  }

  excludeElement(itinerary, data)
  {
    data.forEach(obj => {
      itinerary.segments.forEach(segment => {
        if(segment.TSBlock.id !== obj.blockId) return;
        segment.elements.forEach((element, index) => {
          if(element.TSOrderline.id == obj.lineId) {
            element.optional = true;
          }
        });
      });
    });

    return itinerary;
  }

  setRequired(itinerary, data) 
  {
    data.forEach(obj => {
      let foundBlock = false;
      let skipRest = false;
      let currentGroup = [];
      let skipOptionalGroup = false;
      
      itinerary.segments.forEach(segment => {
        if(segment.TSBlock.id !== obj.blockId) return;
        segment.elements.forEach((element, index) => {
          if(element.TSOrderline.id == obj.lineId) {
            if(element.optional == false && currentGroup.length && currentGroup[0].optional == true) {
              currentGroup[0].optional = false;
              element.optional = true;
              skipOptionalGroup = true;
            } else {
              element.optional = false;
              foundBlock = true;
            }
          } else if(element.isDefault && !foundBlock) {
            currentGroup = [element];
          } else if(element.isDefault && foundBlock) {
            skipRest = true;
          } else if(!skipRest) {
            currentGroup.push(element);
          }
        });
      });
  
      if(!skipOptionalGroup) {
        currentGroup.forEach(element => {
          element.optional = true;
        });
      }
    });
    
    
    return this.addPriceDifference(itinerary);
  }

  setObjectRequired(itinerary, data) 
  {
    data.forEach(obj => {
      let foundBlock = false;
      let skipRest = false;
      let currentGroup = [];
      let skipOptionalGroup = false;
      
      itinerary.segments.forEach(segment => {
        if(segment.vtbObjectId !== obj.vtbObjectIdSegment) return;
        segment.elements.forEach((element, index) => {
          if(element.vtbObjectId == obj.vtbObjectIdElement) {
            if(element.optional == false && currentGroup.length && currentGroup[0].optional == true) {
              currentGroup[0].optional = false;
              element.optional = true;
              skipOptionalGroup = true;
            } else {
              element.optional = false;
              foundBlock = true;
            }
          } else if(element.isDefault && !foundBlock) {
            currentGroup = [element];
          } else if(element.isDefault && foundBlock) {
            skipRest = true;
          } else if(!skipRest) {
            currentGroup.push(element);
          }
        });
      });
  
      if(!skipOptionalGroup) {
        currentGroup.forEach(element => {
          element.optional = true;
        });
      }
    });
    
    
    return this.addPriceDifference(itinerary);
  }
}
