import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PricesService } from 'src/app/services/prices.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { decode } from '@angular/router/src/url_tree';

@Component({
  selector: 'app-template',
  templateUrl: './minisite.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinisiteComponent implements OnInit {
  itinerary: any;
  showMobileMenu: boolean;
  sharedVtbs = [];
  hasValidToken = true;
  initForm = false;
  initLazyLoad = false;

  constructor(private _activatedRoute: ActivatedRoute, private http: HttpClient, private pricesService: PricesService, private cdr:ChangeDetectorRef, private zone: NgZone) {
    const data = this._activatedRoute.snapshot.data;
    console.log(JSON.stringify(data.itinerary));
    this.itinerary = this.pricesService.init(data.itinerary);
    this.loadSharedVTB();
    window.addEventListener('vtbChangePrice', (data:any) => {
      this.itinerary = this.pricesService.doObjectToggle(this.itinerary, data.detail.data, data.detail.optional);
    });
    console.log(this.itinerary);

    this.hasValidToken = this.checkToken();
    
    window.addEventListener('vtbDocuments', (data:any) => {
      let hasTravelDocuments = false;
      Object.keys(data.detail.documents).forEach(key => {
        data.detail.documents[key].forEach(document => {
          console.log(document.fileName.toLowerCase());
          if(document.fileName.toLowerCase().indexOf('reisbescheid') > -1) {
            hasTravelDocuments = true;
          }
        });
      });

      if(hasTravelDocuments) {
        this.itinerary.hasInvoice = false;
        this.itinerary.hasTravelDocuments = true;
      } else {
        this.itinerary.hasInvoice = (data.detail.documents&&data.detail.documents.invoice);
        this.itinerary.hasTravelDocuments = false;
      }
      
      this.cdr.detectChanges();
    });
  }

  trackByFn(index, item)
  {
    return index;
  }

  showForm()
  {
    if(!this.initForm) {
      this.initForm = true;
      this.cdr.detectChanges();
    }
  }

  checkToken()
  {
    let helper = new JwtHelperService();
    let token = this.getCookie('session-token');

    if(token) {
      let decoded = helper.decodeToken(token);
      console.log(decoded, `${window.location.origin}/user_authorization`);
      let origin = 'https://opreismet.vanverre.nl';
      if(!decoded[`${window.location.origin}/user_authorization`] || !decoded[`${window.location.origin}/user_authorization`].app_metadata || !decoded[`${window.location.origin}/user_authorization`].app_metadata.clientId)
        return false;   

      let foundClient = false;
      let clientId = decoded[`${window.location.origin}/user_authorization`].app_metadata.clientId;
      Object.keys(this.itinerary.participants).forEach(party => {
        this.itinerary.participants[party].forEach(participant => {
          if(parseInt(participant.id) === clientId) 
            foundClient = true;
        });
      });
      console.log('foundClient', foundClient);
      return foundClient;
    }

    return true;
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  loadSharedVTB()
  {
    if(this.itinerary.niceUrl) {
      this.http.post('https://hq735ui1t4.execute-api.us-east-1.amazonaws.com/dev/vtbShared', JSON.stringify({
        url: this.itinerary.niceUrl,
        clientId: this.itinerary.clientId,
        tsOrder: this.itinerary.TSOrder.id
      }), {
        headers: {
          'x-api-key': 'dDBd1a4vHvaWNHq3bomBE7QUcxYHeslr19ay2yrZ',
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).subscribe((documents:any) => {
        if(documents && documents.body) {
          this.sharedVtbs = documents.body;
          this.sharedVtbs = this.sharedVtbs.map(item => {
            item.itinerary = JSON.parse(item.itinerary);
            let frags = item.url.split('.com/');
            item.cleanUrl = frags[1];
            return item;
          });
          this.cdr.detectChanges();
        }
      });
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    var event = new CustomEvent('vtbInited');
    window.dispatchEvent(event);

    this.zone.runOutsideAngular(() => {
      window.addEventListener('scroll', (event) => {
        if(!this.initLazyLoad) {
          let top = event.target['scrollingElement'].scrollTop;
          if(top > 400) {
            this.initLazyLoad = true;
            this.cdr.detectChanges();
          }
        }
      });
    });
  }

  changeParticipants(data)
  {
    this.itinerary = this.pricesService.changeParticipants(this.itinerary, data);
    this.cdr.detectChanges();
  }

  setRequired(data) 
  {
    this.itinerary = this.pricesService.setRequired(this.itinerary, data);
    this.cdr.detectChanges();
  }

  setObjectRequired(data) 
  {
    this.itinerary = this.pricesService.setObjectRequired(this.itinerary, data);
    this.cdr.detectChanges();
  }

  doToggle(data)
  {
    this.itinerary = this.pricesService.doToggle(this.itinerary, data);
    this.cdr.detectChanges();
  }

  doObjectToggle(data, optional)
  {
    this.itinerary = this.pricesService.doObjectToggle(this.itinerary, data, optional);
    this.cdr.detectChanges();
  }

  includeElement(data)
  {
    this.itinerary = this.pricesService.includeElement(this.itinerary, data);
    this.cdr.detectChanges();
  }

  excludeElement(data)
  {
    this.itinerary = this.pricesService.excludeElement(this.itinerary, data);
    this.cdr.detectChanges();
  }

}
